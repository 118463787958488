<template>
  <div class="pb-5 flex flex-col w-1/5 mx-auto pt-24">
    <label>
      <b>{{ $t("login.username") }}</b>
    </label>
    <input
      class="border-2 border-black my-3 p-1"
      type="text"
      :placeholder="$t('login.userNamePlaceholder')"
    />

    <label>
      <b>{{ $t("login.password") }}</b>
    </label>
    <input
      class="border-2 border-black my-3 p-1"
      type="password"
      :placeholder="$t('login.passwordPlaceholder')"
    />

    <button class="border-2 bg-green-400 p-1 w-24" type="submit">
      {{ $t("login.login") }}
    </button>
  </div>
</template>
